// import tile_api from '../api/tiles/tiles_api';
import _ from "lodash";
import { API, graphqlOperation } from "aws-amplify";
import {
  listTiles,
  listPages,
  listImages,
  listTrusts,
  listSections,
  listPathways,
  pagesByUserId,
  sectionsByUserId,
  sectionsSectionId,
  junctionPagesByUserId,
  tilesByUserId,
  listWellnessForms,
  listQuestions,
  listTriggers,
  analyticsById,
  listAnalytics,
  pathwayByUserId,
  wellnessFormByUserId,
  questionByUserId,
  listSystemInfos,
  systemInfoByDomain,
  listOnboardingInfos,
  onboardingInfoByDomain,
  pagesByPageId,
} from "../graphql/queries";

import {
  createTile,
  createImage,
  updateTile,
  deleteTile,
  createPage,
  updatePage,
  deletePage,
  createTrust,
  updateTrust,
  deleteTrust,
  createSection,
  updateSection,
  deleteSection,
  updateImage,
  deleteImage,
  createPathway,
  updatePathway,
  deletePathway,
  createJunctionPage,
  updateJunctionPage,
  deleteJunctionPage,
  createWellnessForm,
  deleteWellnessForm,
  updateWellnessForm,
  createQuestion,
  updateQuestion,
  deleteQuestion,
  deleteTrigger,
  createTrigger,
  updateTrigger,
  deleteAnalytics,
  createSystemInfo,
  createOnboardingInfo,
  deleteSystemInfo,
  deleteOnboardingInfo,
  updateOnboardingInfo,
} from "../graphql/mutations";

export const fetchTiles = (user) => {
  return async (dispatch) => {
    try {
      console.log(user);
      const response = await API.graphql(
        graphqlOperation(tilesByUserId, { user_id: user })
      );
      const ordered_tiles = _.sortBy(response.data.tilesByUserId.items, [
        "index_number",
      ]);
      dispatch({
        type: "FETCH_TILES",
        payload: response.data.tilesByUserId.items,
      });
      //dispatch({ type: 'FETCH_TILES', payload: response.data.listTiles.items })
    } catch (err) {
      console.log("error fetching data...", err);
    }
  };
};

export const addTile = (tile, user) => {
  let myInit = {
    body: tile,
    headers: {
      app_user_id: user,
      app_user_name: "Test User",
    },
  };
  return async (dispatch) => {
    try {
      // console.log('trying to add tiles')
      const response = await API.graphql(
        graphqlOperation(createTile, { input: tile.Item })
      );
      // console.log(response.data.createTile)
      dispatch({ type: "ADD_TILE", payload: response.data.createTile });
    } catch (error) {
      console.log("error fetching data...", error);
    }
  };
};

export const update_Tile = (tile, user) => {
  console.log("updating tile");
  let myInit = {
    body: tile,
    headers: {
      app_user_id: user,
      app_user_name: "Test User",
    },
  };
  return async (dispatch) => {
    try {
      const response = await API.graphql(
        graphqlOperation(updateTile, { input: tile.Item })
      );
      console.log(response.data.updateTile);
      dispatch({ type: "UPDATE_TILE", payload: response.data.updateTile });
    } catch (error) {
      console.log(error);
    }
  };
};

export const removeTile = (tile, user) => {
  return async (dispatch) => {
    try {
      const response = await API.graphql(
        graphqlOperation(deleteTile, { input: { id: tile.id } })
      );
      console.log(response);
      dispatch({ type: "REMOVE_TILE", payload: tile.id });
    } catch (error) {
      console.log(error);
    }
  };
};

export const authStatus = (data) => {
  return {
    type: "LOG_IN",
    payload: data,
  };
};

export const updateUserInfo = (data) => {
  return {
    type: "UPDATE_USER_INFO",
    payload: data,
  };
};

export const fetchPages = (user) => {
  console.log("Fetching Pages");
  return async (dispatch) => {
    try {
      //Set up array to push to
      var data_array = [];

      // Fetch Trust Pages
      const response = await API.graphql(
        graphqlOperation(pagesByUserId, { user_id: user })
      );
      data_array.push(response.data.pagesByUserId.items);
      var token = response.data.pagesByUserId?.nextToken;

      if (!!token) {
        for (let complete = false; complete === false; ) {
          var new_data = await API.graphql(
            graphqlOperation(pagesByUserId, { user_id: user, nextToken: token })
          );

          data_array.push(new_data.data.pagesByUserId.items);
          token = new_data.data.pagesByUserId.nextToken;
          if (new_data.data.pagesByUserId.nextToken === null) {
            complete = true;
          }
        }
      }

      // Fetch Common Pages

      const common = await API.graphql(
        graphqlOperation(pagesByUserId, { user_id: "common" })
      );
      data_array.push(common.data.pagesByUserId.items);
      var common_token = common.data.pagesByUserId?.nextToken;
      if (!!common_token) {
        for (let complete = false; complete === false; ) {
          var new_data = await API.graphql(
            graphqlOperation(pagesByUserId, {
              user_id: "common",
              nextToken: common_token,
            })
          );

          data_array.push(new_data.data.pagesByUserId.items);
          common_token = new_data.data.pagesByUserId.nextToken;
          if (common_token === null) {
            complete = true;
          }
        }
      }

      console.log("data_array");
      console.log(data_array);

      var combined = _.flattenDeep(data_array);
      console.log("combined");
      console.log(combined);
      const ordered_pages = _.sortBy(combined, ["title"]);
      dispatch({ type: "FETCH_PAGES", payload: ordered_pages });
    } catch (err) {
      console.log("error fetching data...", err);
    }
  };
};

export const fetchPage = async (id) => {
  console.log("Get Page" + " " + id);
  try {
    console.log(id);
    const response = await API.graphql(
      graphqlOperation(pagesByPageId, { page_id: id })
    );
    return response.data.pagesByPageId.items[0];
  } catch (err) {
    console.log("error fetching data...", err);
  }
};

export const fetchTemplates = (user) => {
  return async (dispatch) => {
    try {
      console.log(user);
      const response = await API.graphql(
        graphqlOperation(pagesByUserId, { user_id: "template" })
      );
      const ordered_pages = _.sortBy(response.data.pagesByUserId.items, [
        "title",
      ]);
      console.log(ordered_pages);
      dispatch({ type: "FETCH_TEMPLATES", payload: ordered_pages });
    } catch (err) {
      console.log("error fetching data...", err);
    }
  };
};

export const update_Page = (page, user) => {
  let myInit = {
    body: page,
    headers: {
      app_user_id: user,
      app_user_name: "Test User",
    },
  };
  return async (dispatch) => {
    try {
      console.log("inside update");
      console.log(page.Item);
      const response = await API.graphql(
        graphqlOperation(updatePage, { input: page.Item })
      );
      console.log(response.data.updatePage);
      if (response.data.updatePage.user_id === "template") {
        dispatch({
          type: "UPDATE_TEMPLATE",
          payload: response.data.updatePage,
        });
      }

      dispatch({ type: "UPDATE_PAGE", payload: response.data.updatePage });
    } catch (error) {
      console.log(error);
    }
  };
};

export const removePage = (page) => {
  const page_id = page.id;

  return async (dispatch) => {
    try {
      // first get the Sections
      const sections = await API.graphql(
        graphqlOperation(sectionsSectionId, { section_id: page.page_id })
      );

      // If sections not 0 then delete them
      if (sections.data.sectionsSectionId.items.length !== 0) {
        const responses = sections.data.sectionsSectionId.items.map(
          async (section) => {
            const response = await API.graphql(
              graphqlOperation(deleteSection, { input: { id: section.id } })
            );
            dispatch({
              type: "REMOVE_SECTION",
              payload: response.data.deleteSection.id,
            });
            console.log(response);
            return response;
          }
        );
        await Promise.all(responses);
      }

      // then delete the page
      const response = await API.graphql(
        graphqlOperation(deletePage, { input: { id: page_id } })
      );

      if (response.data.deletePage.user_id === "template") {
        dispatch({ type: "REMOVE_TEMPLATE", payload: page_id });
      }
      dispatch({ type: "REMOVE_PAGE", payload: page_id });
    } catch (error) {
      console.log(error);
    }
  };
};

export const addPage = (tile) => {
  return async (dispatch) => {
    try {
      console.log("adding....page");
      console.log(tile.Item);

      const response = await API.graphql(
        graphqlOperation(createPage, { input: tile.Item })
      );
      console.log("response.data.createPage");
      console.log(response.data.createPage);
      dispatch({ type: "ADD_PAGE", payload: response.data.createPage });
    } catch (error) {
      console.log("error fetching data...", error);
    }
  };
};

export const fetchSections = (page_id) => {
  console.log("get sections");
  return async (dispatch) => {
    try {
      var data_array = [];

      const list = await API.graphql(
        graphqlOperation(sectionsByUserId, { user_id: page_id })
      );
      console.log("list display here");
      console.log(list);
      data_array.push(list.data.sectionsByUserId.items);
      var token = list.data.sectionsByUserId.nextToken;
      if (list.data.sectionsByUserId.nextToken) {
        for (let complete = false; complete === false; ) {
          var new_data = await API.graphql(
            graphqlOperation(sectionsByUserId, {
              user_id: page_id,
              nextToken: token,
            })
          );
          data_array.push(new_data.data.sectionsByUserId.items);
          token = new_data.data.sectionsByUserId.nextToken;
          if (new_data.data.sectionsByUserId.nextToken === null) {
            complete = true;
          }
        }
      }

      const list_common = await API.graphql(
        graphqlOperation(sectionsByUserId, { user_id: "common" })
      );
      data_array.push(list_common.data.sectionsByUserId.items);
      var token_common = list_common.data.sectionsByUserId.nextToken;
      if (list_common.data.sectionsByUserId.nextToken) {
        for (let complete = false; complete === false; ) {
          var new_data_common = await API.graphql(
            graphqlOperation(sectionsByUserId, {
              user_id: "common",
              nextToken: token_common,
            })
          );
          data_array.push(new_data_common.data.sectionsByUserId.items);
          token_common = new_data_common.data.sectionsByUserId.nextToken;
          if (new_data_common.data.sectionsByUserId.nextToken === null) {
            complete = true;
          }
        }
      }

      const list_template = await API.graphql(
        graphqlOperation(sectionsByUserId, { user_id: "template" })
      );
      data_array.push(list_template.data.sectionsByUserId.items);
      var token_template = list_template.data.sectionsByUserId.nextToken;
      if (list_template.data.sectionsByUserId.nextToken) {
        for (let complete = false; complete === false; ) {
          var new_data_template = await API.graphql(
            graphqlOperation(sectionsByUserId, {
              user_id: "template",
              nextToken: token_template,
            })
          );
          data_array.push(new_data_template.data.sectionsByUserId.items);
          token_template = new_data_template.data.sectionsByUserId.nextToken;
          if (new_data_template.data.sectionsByUserId.nextToken === null) {
            complete = true;
          }
        }
      }

      const flattenArray = _.flattenDeep(data_array);
      console.log("flattenArray");
      const ordered_sections = _.sortBy(flattenArray, ["index_number"]);
      console.log("ordered_sections");
      console.log(ordered_sections);
      dispatch({ type: "FETCH_SECTIONS", payload: ordered_sections });
    } catch (err) {
      console.log("error fetching data...", err);
    }
    //const response = await API.get('api', 'sections', myInit);
    //const ordered_sections = _.sortBy(response, ['index_number'])
    //dispatch({ type: 'FETCH_SECTIONS', payload: ordered_sections })
  };
};

export const update_Section = (section) => {
  return async (dispatch) => {
    try {
      const response = await API.graphql(
        graphqlOperation(updateSection, { input: section.Item })
      );
      console.log(response.data.updateSection);
      dispatch({
        type: "UPDATE_SECTION",
        payload: response.data.updateSection,
      });
    } catch (error) {
      console.log(error);
    }
    //const response = await API.patch('api', 'section', myInit);
    //console.log(response)
    //dispatch({ type: 'UPDATE_SECTION', payload: response })
  };
};

export const removeSection = (id) => {
  console.log("Removing section");
  console.log("id");
  console.log(id);
  return async (dispatch) => {
    try {
      const response = await API.graphql(
        graphqlOperation(deleteSection, { input: { id: id } })
      );
      console.log(response);
      dispatch({
        type: "REMOVE_SECTION",
        payload: response.data.deleteSection.id,
      });
    } catch (error) {
      console.log(error);
    }
  };
};

export const addSection = (data) => {
  return async (dispatch) => {
    try {
      const response = await API.graphql(
        graphqlOperation(createSection, { input: data })
      );
      console.log("response.data.createSection");
      console.log(response.data.createSection);
      dispatch({ type: "ADD_SECTION", payload: response.data.createSection });
    } catch (error) {
      console.log("error fetching data...", error);
    }
    //const response = await API.post('api', 'section', myInit);
    //dispatch({ type: 'ADD_SECTION', payload: response})
  };
};

export const fetchTrusts = () => {
  return async (dispatch) => {
    try {
      console.log("trying to get trusts");
      const response = await API.graphql(graphqlOperation(listTrusts));
      console.log(response.data.listTrusts.items);
      dispatch({
        type: "FETCH_TRUSTS",
        payload: response.data.listTrusts.items,
      });
    } catch (err) {
      console.log("error fetching data...", err);
    }
  };
};

export const update_Trust = (trust) => { 
 
  return async (dispatch) => {
    try { 
      const response = await API.graphql(
        graphqlOperation(updateTrust, { input: trust })
      ); 
      dispatch({ type: "UPDATE_TRUST", payload: response.data.updateTrust });
    } catch (error) {
      console.log(error);
    }

    //const response = await API.patch('api', 'trust', myInit);
    //dispatch({ type: 'UPDATE_TRUST', payload: response })
  };
};

export const removeTrust = (trust) => {
  return async (dispatch) => {
    // WILL NEED TO SET UP REMOVING ALL ASSOCIATED SECTIONS ETC
    try {
      const response = await API.graphql(
        graphqlOperation(deleteTrust, { input: { id: trust.id } })
      );
      console.log(response.data.deleteTrust.id);
      dispatch({ type: "REMOVE_TRUST", payload: trust.id });
    } catch (error) {
      console.log(error);
    }
  };
};

export const copyTrust = (trust_timestamp) => {
  let myInit = {
    body: { Item: { name: "New Trust" } },
    headers: {
      app_user_id: "base_user",
      app_user_name: "Test User",
    },
  };
  return async (dispatch) => {
    const ext = "trust/t/" + trust_timestamp;
    //await API.post('api', ext, myInit);
    //dispatch({ type: 'ADD_TRUST', payload: trust_timestamp })
  };
};

export const addTrust = (trust) => {
  let myInit = {
    body: trust,
    headers: {
      app_user_id: "base_user",
    },
  };
  return async (dispatch) => {
    try {
      const response = await API.graphql(
        graphqlOperation(createTrust, { input: trust.Item })
      );
      dispatch({ type: "ADD_TRUST", payload: response.data.createTrust });
    } catch (error) {
      console.log("error fetching data...", error);
    }
  };
};

export const fetchPathways = (user) => {
  console.log("trying to get pathways");

  return async (dispatch) => {
    try {
      //const response = await API.graphql(graphqlOperation(listPathways))
      const response = await API.graphql(
        graphqlOperation(pathwayByUserId, { user_id: user })
      );
      // const ordered_pathways = _.sortBy(response.data.listPathways.items, ['index_number'])
      console.log(response);
      dispatch({
        type: "FETCH_PATHWAYS",
        payload: response.data.PathwayByUserId.items,
      });
    } catch (err) {
      console.log("error fetching data...", err);
    }
  };
};

export const fetchAllPathways = async () => {
  console.log("trying to get All pathways");

  try {
    //const response = await API.graphql(graphqlOperation(listPathways))
    var data_array = [];
    const response = await API.graphql(graphqlOperation(listPathways));
    data_array.push(response.data.listPathways.items);
 
    ///////
    var token = null;
    if (response.data.listPathways.nextToken) {
      token = response.data.listPathways.nextToken;
      console.log("we have a loop");
      for (let complete = false; complete === false; ) {
        var new_data = await API.graphql(
          graphqlOperation(listPathways, {
            nextToken: token,
          })
        );
        data_array.push(new_data.data.listPathways.items);

        token = new_data.data.listPathways.nextToken;
        if (new_data.data.listPathways.nextToken === null) {
          complete = true;
        }
      }
    }

    const flattenArray = _.flattenDeep(data_array);

    //////
    return flattenArray
  } catch (err) {
    console.log("error fetching data...", err);
  }
};

export const addPathway = (title, user) => {
  let params = {
    user_id: user,
    timeline_data: JSON.stringify([]),
    user_name: "Test User",
    title: title,
    image_url: null,
  };
  return async (dispatch) => {
    try {
      console.log("trying to add PATHWAY");
      const response = await API.graphql(
        graphqlOperation(createPathway, { input: params })
      );
      // console.log(response.data.createTile)
      dispatch({ type: "ADD_PATHWAY", payload: response.data.createPathway });
    } catch (error) {
      console.log("error fetching data...", error);
    }
  };
};

export const update_Pathway = (data) => {
  console.log("updating pathway");
  console.log(data);
  return async (dispatch) => {
    try {
      const response = await API.graphql(
        graphqlOperation(updatePathway, { input: data })
      );
      console.log("response from updating pathway");
      console.log(response);
      dispatch({
        type: "UPDATE_PATHWAY",
        payload: response.data.updatePathway,
      });
    } catch (error) {
      console.log(error);
    }
  };
};

export const removePathway = (pathway) => {
  return async (dispatch) => {
    try {
      const response = await API.graphql(
        graphqlOperation(deletePathway, { input: { id: pathway.id } })
      );
      dispatch({ type: "REMOVE_PATHWAY", payload: pathway.id });
    } catch (error) {
      console.log(error);
    }
  };
};

/////// JUNCTION PAGES  ///////////////

export const add_junctionPage = (data, user) => {
  return async (dispatch) => {
    try {
      console.log("trying to add Junction Page");
      const response = await API.graphql(
        graphqlOperation(createJunctionPage, { input: data })
      );
      console.log(response.data.createJunctionPage);
      dispatch({
        type: "ADD_JUNCTION_PAGE",
        payload: response.data.createJunctionPage,
      });
    } catch (error) {
      console.log("error fetching data...", error);
    }
  };
};

export const fetchJunctionPages = (user) => {
  return async (dispatch) => {
    try {
      console.log("Getting JP");
      console.log(user);
      const response = await API.graphql(
        graphqlOperation(junctionPagesByUserId, { user_id: user })
      );
      //const ordered_tiles = _.sortBy(response.data.junctionPagesByUserId.items, ['index_number'])
      console.log(response);
      // console.log(ordered_tiles)
      dispatch({
        type: "FETCH_JUNCTION_PAGES",
        payload: response.data.junctionPagesByUserId.items,
      });
    } catch (err) {
      console.log("error fetching data...", err);
    }
  };
};

export const removeJunctionPage = (junction_page, user) => {
  return async (dispatch) => {
    try {
      const response = await API.graphql(
        graphqlOperation(deleteJunctionPage, {
          input: { id: junction_page.id },
        })
      );
      dispatch({ type: "REMOVE_JUNCTION_PAGE", payload: junction_page.id });
    } catch (error) {
      console.log(error);
    }
  };
};
export const update_JunctionPage = (junction_page) => {
  return async (dispatch) => {
    try {
      const response = await API.graphql(
        graphqlOperation(updateJunctionPage, { input: junction_page })
      );
      dispatch({
        type: "UPDATE_JUNCTION_PAGE",
        payload: response.data.updateJunctionPage,
      });
    } catch (error) {
      console.log(error);
    }
  };
};

// Wellness Tracking Actions

export const addWellnessForm = (params) => {
  return async (dispatch) => {
    try {
      console.log("trying to add Wellness Form");
      const response = await API.graphql(
        graphqlOperation(createWellnessForm, { input: params })
      );
      console.log(response.data.createWellnessForm);
      dispatch({
        type: "ADD_WELLNESS_FORM",
        payload: response.data.createWellnessForm,
      });
    } catch (error) {
      console.log("error fetching data...", error);
    }
  };
};

export const fetchWellnessForms = (user) => {
  console.log("trying to get pathways");
  let myInit = {
    headers: {
      app_user_id: user,
      app_user_name: "Test User",
    },
  };
  return async (dispatch) => {
    try {
      const response = await API.graphql(
        graphqlOperation(listWellnessForms, { user_id: user })
      );
      dispatch({
        type: "FETCH_WELLNESS_FORMS",
        payload: response.data.listWellnessForms.items,
      });
    } catch (err) {
      console.log("error fetching data...", err);
    }
  };
};
export const removeWellnessForm = (form) => {
  return async (dispatch) => {
    try {
      console.log("form.id");
      console.log(form.id);
      const response = await API.graphql(
        graphqlOperation(deleteWellnessForm, { input: { id: form.id } })
      );
      dispatch({ type: "REMOVE_WELLNESS_FORM", payload: form.id });
    } catch (error) {
      console.log(error);
    }
  };
};

export const update_WellnessForm = (wellnessForm) => {
  return async (dispatch) => {
    try {
      const response = await API.graphql(
        graphqlOperation(updateWellnessForm, { input: wellnessForm })
      );
      console.log(response.data.updateWellnessForm);
      dispatch({
        type: "UPDATE_WELLNESS_FORM",
        payload: response.data.updateWellnessForm,
      });
    } catch (error) {
      console.log(error);
    }
  };
};

// Wellness Questions

export const addWellnessQuestion = (params, user) => {
  return async (dispatch) => {
    try {
      console.log("trying to add Wellness Form");
      const response = await API.graphql(
        graphqlOperation(createQuestion, { input: params })
      );
      console.log(response.data.createQuestion);
      dispatch({
        type: "ADD_WELLNESS_QUESTION",
        payload: response.data.createQuestion,
      });
    } catch (error) {
      console.log("error fetching data...", error);
    }
  };
};

///// FETCH QUESTIONS
export const fetchWellnessQuestions = (user) => {
  console.log("trying to get questions");
  return async (dispatch) => {
    try {
      const response = await API.graphql(
        graphqlOperation(questionByUserId, { user_id: user })
      );
      // const ordered_pathways = _.sortBy(response.data.listPathways.items, ['index_number'])
      //console.log(response.data.QuestionByUserId.items)
      console.log("response");
      console.log(response);
      dispatch({
        type: "FETCH_WELLNESS_QUESTIONS",
        payload: response.data.QuestionByUserId.items,
      });
    } catch (err) {
      console.log("error fetching data...", err);
    }
  };
};
export const removeWellnessQuestion = (question) => {
  return async (dispatch) => {
    try {
      console.log("question.id");
      console.log(question.id);
      const response = await API.graphql(
        graphqlOperation(deleteQuestion, { input: { id: question.id } })
      );
      dispatch({ type: "REMOVE_WELLNESS_QUESTION", payload: question.id });
    } catch (error) {
      console.log(error);
    }
  };
};

export const update_WellnessQuestion = (wellnessQuestion) => {
  console.log("we are here");
  console.log(wellnessQuestion);
  return async (dispatch) => {
    try {
      const response = await API.graphql(
        graphqlOperation(updateQuestion, { input: wellnessQuestion })
      );
      console.log(response);
      dispatch({
        type: "UPDATE_WELLNESS_QUESTION",
        payload: response.data.updateQuestion,
      });
    } catch (error) {
      console.log(error);
    }
  };
};

////////////

export const addTrigger = (params) => {
  return async (dispatch) => {
    try {
      console.log("trying to add Trigger");
      const response = await API.graphql(
        graphqlOperation(createTrigger, { input: params })
      );
      console.log(response.data.createTrigger);
      dispatch({
        type: "ADD_WELLNESS_TRIGGER",
        payload: response.data.createTrigger,
      });
    } catch (error) {
      console.log("error fetching data...", error);
    }
  };
};

export const fetchTriggers = (user) => {
  console.log("trying to get triggers");
  let myInit = {
    headers: {
      app_user_id: user,
      app_user_name: "Test User",
    },
  };
  return async (dispatch) => {
    try {
      const response = await API.graphql(graphqlOperation(listTriggers));
      // const ordered_pathways = _.sortBy(response.data.listPathways.items, ['index_number'])
      console.log(response.data.listTriggers.items);
      dispatch({
        type: "FETCH_WELLNESS_TRIGGERS",
        payload: response.data.listTriggers.items,
      });
    } catch (err) {
      console.log("error fetching data...", err);
    }
  };
};
export const removeTrigger = (trigger) => {
  return async (dispatch) => {
    try {
      console.log("question.id");
      console.log(trigger.id);
      const response = await API.graphql(
        graphqlOperation(deleteTrigger, { input: { id: trigger.id } })
      );
      dispatch({ type: "REMOVE_WELLNESS_TRIGGER", payload: trigger.id });
    } catch (error) {
      console.log(error);
    }
  };
};

export const update_Trigger = (params) => {
  console.log("we are here");
  console.log(params);
  return async (dispatch) => {
    try {
      const response = await API.graphql(
        graphqlOperation(updateTrigger, { input: params })
      );
      console.log(response);
      dispatch({
        type: "UPDATE_WELLNESS_TRIGGER",
        payload: response.data.updateTrigger,
      });
    } catch (error) {
      console.log(error);
    }
  };
};

// ANALYTICS

// export const addAnalytics = (params) => {
//   return async (dispatch) => {
//     try {
//       console.log('trying to add Wellness Form')
//       const response = await API.graphql(graphqlOperation(createTrigger,{input: params}))
//       console.log(response.data.createTrigger)
//       dispatch({ type: 'ADD_WELLNESS_TRIGGER', payload: response.data.createTrigger})
//     } catch (error) {
//       console.log('error fetching data...', error)
//     }
// };
// };

export const fetchAnalyticsByID = (user) => {
  console.log("trying to get analytics");
  let myInit = {
    headers: {
      app_user_id: user,
      app_user_name: "Test User",
    },
  };
  return async (dispatch) => {
    try {
      const response = await API.graphql(graphqlOperation(analyticsById));
      // const ordered_pathways = _.sortBy(response.data.listPathways.items, ['index_number'])
      console.log(response.data.analyticsById.items);
      dispatch({
        type: "FETCH_ANALYTICS_BY_ID",
        payload: response.data.analyticsById.items,
      });
    } catch (err) {
      console.log("error fetching data...", err);
    }
  };
};

export const fetchAnalytics = (user) => {
  console.log("trying to get analytics");
  let myInit = {
    headers: {
      app_user_id: user,
      app_user_name: "Test User",
    },
  };
  return async (dispatch) => {
    try {
      const response = await API.graphql(graphqlOperation(listAnalytics));
      // const ordered_pathways = _.sortBy(response.data.listPathways.items, ['index_number'])
      console.log(response.data.listAnalytics.items);
      dispatch({
        type: "FETCH_ANALYTICS",
        payload: response.data.listAnalytics.items,
      });
    } catch (err) {
      console.log("error fetching data...", err);
    }
  };
};
export const removeAnalytics = (entry) => {
  return async (dispatch) => {
    try {
      console.log("entry.id");
      console.log(entry.id);
      const response = await API.graphql(
        graphqlOperation(deleteAnalytics, { input: { id: entry.id } })
      );
      dispatch({ type: "REMOVE_WELLNESS_ANALYTICS", payload: entry.id });
    } catch (error) {
      console.log(error);
    }
  };
};
//////

export const fetchSystemIfoByDomain = (domain) => {
  console.log("trying to get systemInfo by domain");
  return async (dispatch) => {
    try {
      const response = await API.graphql(
        graphqlOperation(onboardingInfoByDomain, { domain: domain })
      );
      // const ordered_pathways = _.sortBy(response.data.listPathways.items, ['index_number'])
      console.log(response.data.OnboardingInfoByDomain.items);
      console.log(response);
      dispatch({
        type: "FETCH_SYSTEM_INFO_BY_DOMAIN",
        payload: response.data.OnboardingInfoByDomain.items,
      });
    } catch (err) {
      console.log("error fetching data...", err);
    }
  };
};

export const fetchSystemInfo = () => {
  console.log("trying to get SystemInfo");
  return async (dispatch) => {
    try {
      const response = await API.graphql(graphqlOperation(listOnboardingInfos));
      console.log(response);
      // const ordered_pathways = _.sortBy(response.data.listPathways.items, ['index_number'])

      dispatch({
        type: "FETCH_SYSTEM_INFO",
        payload: response.data.ListOnboardingInfos.items,
      });
    } catch (err) {
      console.log("error fetching data...", err);
    }
  };
};

export const addSystemInfo = (params) => {
  return async (dispatch) => {
    try {
      console.log("trying to add System Info");
      // const response = await API.graphql({
      //   query: createOnboardingInfo,
      //   variables: { input: params },
      //   authMode: "AWS_IAM",
      // });
      const response = await API.graphql(
        graphqlOperation(createOnboardingInfo, { input: params })
      );
      console.log(response.data.createOnboardingInfo);
      dispatch({
        type: "ADD_SYSTEM_INFO",
        payload: response.data.createOnboardingInfo,
      });
    } catch (error) {
      console.log("error fetching data...", error);
    }
  };
};

export const updateSystemInfo = (params) => {
  console.log("we are here updating the info");
  console.log(params);
  return async (dispatch) => {
    try {
      const response = await API.graphql(
        graphqlOperation(updateOnboardingInfo, { input: params })
      );
      console.log(response.data.updateOnboardingInfo);
      dispatch({
        type: "UPDATE_SYSTEM_INFO",
        payload: response.data.updateOnboardingInfo,
      });
    } catch (error) {
      console.log(error);
    }
  };
};

export const removeSystemInfo = (id) => {
  return async (dispatch) => {
    try {
      console.log("delete id");
      console.log(id);
      const response = await API.graphql(
        graphqlOperation(deleteOnboardingInfo, { input: { id: id } })
      );
      dispatch({ type: "REMOVE_SYSTEM_INFO", payload: id });
    } catch (error) {
      console.log(error);
    }
  };
};

// export const update_Trigger = (params) => {
//   console.log('we are here')
//   console.log(params)
//   return async (dispatch) => {
//     try {
//       const response = await API.graphql(graphqlOperation(updateTrigger,{input: params}))
//       console.log(response)
//       dispatch({ type: 'UPDATE_WELLNESS_TRIGGER', payload: response.data.updateTrigger })
//     } catch (error) {
//       console.log(error)
//     }
//   };
// };

export const fetchImages = (user) => {
  return async (dispatch) => {
    try {
      var data_array = [];
      const response = await API.graphql(graphqlOperation(listImages));
      data_array.push(response.data.listImages.items);

      var token = null;
      if (response.data.listImages.nextToken) {
        token = response.data.listImages.nextToken;
        console.log("we have a loop");
        for (let complete = false; complete === false; ) {
          var new_data = await API.graphql(
            graphqlOperation(listImages, {
              nextToken: token,
            })
          );
          data_array.push(new_data.data.listImages.items);

          token = new_data.data.listImages.nextToken;
          if (new_data.data.listImages.nextToken === null) {
            complete = true;
          }
        }
      }

      const flattenArray = _.flattenDeep(data_array);
      dispatch({
        type: "FETCH_IMAGES",
        payload: flattenArray,
      });
      //dispatch({ type: 'FETCH_TILES', payload: response.data.listTiles.items })
    } catch (err) {
      console.log("error fetching data...", err);
    }
  };
};

export const fetchImages2 = (page_id) => {
  console.log("get images asd");
  return async (dispatch) => {
    try {
      var data_array = [];

      const list = await API.graphql(graphqlOperation(listImages));
      console.log("list images here");
      console.log(list);
      data_array.push(list.data.listImages.items);
      var token = list.data.listImages.nextToken;
      if (list.data.listImages.nextToken) {
        console.log("we have a loop");
        for (let complete = false; complete === false; ) {
          var new_data = await API.graphql(graphqlOperation(listImages));
          data_array.push(new_data.data.listImages.items);

          token = new_data.data.listImages.nextToken;
          if (new_data.data.listImages.nextToken === null) {
            complete = true;
          }
        }
      }
      console.log("image data_array");
      console.log(data_array);
      const flattenArray = _.flattenDeep(data_array);
      dispatch({ type: "FETCH_IMAGES", payload: flattenArray });
    } catch (err) {
      console.log("error image fetching data...", err);
    }
    //const response = await API.get('api', 'sections', myInit);
    //const ordered_sections = _.sortBy(response, ['index_number'])
    //dispatch({ type: 'FETCH_SECTIONS', payload: ordered_sections })
  };
};

export const addImage = (image, user) => {
  return async (dispatch) => {
    try {
      console.log("trying to add image");
      const response = await API.graphql(
        graphqlOperation(createImage, { input: image })
      );
      dispatch({ type: "ADD_IMAGE", payload: response.data.createImage });
    } catch (error) {
      console.log("error fetching data...", error);
    }
  };
};

export const update_image = (image) => {
  console.log("updating image");
  return async (dispatch) => {
    try {
      const response = await API.graphql(
        graphqlOperation(updateImage, { input: image })
      );
      console.log(response.data.updateImage);
      dispatch({ type: "UPDATE_IMAGE", payload: response.data.updateImage });
    } catch (error) {
      console.log(error);
    }
  };
};

export const removeImage = (id) => {
  return async (dispatch) => {
    try {
      const response = await API.graphql(
        graphqlOperation(deleteImage, { input: { id: id } })
      );
      dispatch({ type: "REMOVE_IMAGE", payload: id });
    } catch (error) {
      console.log(error);
    }
  };
};
